<script>
  import { services } from "../services.js";

  let searchQuery = "";
  let isShaking = false;
  // Store recent searches in local storage
  let recentSearches = JSON.parse(
    localStorage.getItem("recentSearches") || "[]"
  );

  const handleSearch = (service) => {
    if (!searchQuery.trim()) {
      // Trigger shake animation
      isShaking = true;
      setTimeout(() => {
        isShaking = false;
      }, 500); // Animation duration
      return;
    }

    // Add to recent searches if not already present
    if (!recentSearches.includes(searchQuery.trim())) {
      recentSearches = [searchQuery.trim(), ...recentSearches].slice(0, 10); // Keep last 10 searches
      localStorage.setItem("recentSearches", JSON.stringify(recentSearches));
    }

    // Transform the query if a transformation function exists
    const transformedQuery = service.queryTransform
      ? service.queryTransform(searchQuery)
      : encodeURIComponent(searchQuery);

    const url = service.url + transformedQuery;
    // Open in new tab without affecting current page state
    const newWindow = window.open();
    if (newWindow) {
      newWindow.opener = null;
      newWindow.location = url;
    }
  };
  const removeSearch = (searchTerm) => {
    recentSearches = recentSearches.filter((term) => term !== searchTerm);
    localStorage.setItem("recentSearches", JSON.stringify(recentSearches));
  };

  const useRecentSearch = (searchTerm) => {
    searchQuery = searchTerm;
  };
</script>

<div class="min-h-screen bg-gray-100">
  <!-- Sticky header section -->
  <header class="sticky top-0 bg-gray-100 shadow-sm">
    <!-- Logo -->
    <h1
      class="text-6xl font-bold pt-10 mb-8 bg-gradient-to-r from-blue-500 to-purple-600 text-transparent bg-clip-text text-center"
    >
      Everymov
    </h1>

    <!-- Search Box -->
    <div class="w-full max-w-2xl px-4 mx-auto pb-6">
      <div class="relative">
        <input
          type="text"
          bind:value={searchQuery}
          placeholder="Search..."
          class="w-full px-5 py-3 rounded-full border border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 shadow-sm {isShaking
            ? 'shake'
            : ''}"
        />
        <span
          class="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </div>

      <!-- Recent Searches -->
      {#if recentSearches.length > 0}
        <div class="relative mt-2 pb-6">
          <div class="flex overflow-x-auto scrollbar-hide space-x-2 px-1">
            {#each recentSearches as search}
              <div
                class="flex items-center bg-gray-800 rounded-full px-3 py-1 border border-gray-700 hover:border-purple-500 group"
              >
                <button
                  class="text-gray-300 text-sm whitespace-nowrap"
                  on:click={() => useRecentSearch(search)}
                >
                  {search}
                </button>
                <button
                  class="ml-2 text-gray-500 hover:text-red-500"
                  on:click={() => removeSearch(search)}
                >
                  <svg
                    class="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            {/each}
          </div>
        </div>
      {/if}
    </div>
  </header>

  <!-- Service Buttons -->
  <main class="w-full max-w-4xl px-4 mx-auto py-6">
    <div
      class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3"
    >
      {#each Object.entries(services) as [name, service]}
        <button
          on:click={() => handleSearch(service)}
          class="p-3 text-sm rounded-lg border border-gray-200 hover:border-blue-500 hover:shadow-md transition-all duration-200 bg-white capitalize"
        >
          {name}
        </button>
      {/each}
    </div>
  </main>
</div>

<style>
  @keyframes shake {
    0%,
    100% {
      transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translateX(-4px);
    }
    20%,
    40%,
    60%,
    80% {
      transform: translateX(4px);
    }
  }

  .shake {
    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    border-color: rgb(239, 68, 68) !important; /* red-500 */
  }

  /* ... your existing styles ... */
</style>
