import { parse } from "dotenv";

const PARAMS = [
  // UTM
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  // conversion
  "tracker",
  "aclid",
  "click_id",
  "ca",
  "ha",
  "test",
];

// Parse query parameters from URL
export function parseQueryParams() {
  const urlParams = new URLSearchParams(window.location.search);

  // no params
  if (!PARAMS.some((key) => urlParams.has(key))) {
    return null;
  }

  // parse params
  const params = {};
  PARAMS.forEach((key) => {
    if (urlParams.has(key)) {
      params[key] = urlParams.get(key);
    }
  });
  return params;
}

// Get payment params from URL or local storage
export function getPaymentParams() {
  const parsed = parseQueryParams();
  if (parsed) {
    return parsed;
  } else {
    const stored = localStorage.getItem("params");
    if (stored) {
      const params = JSON.parse(stored);
      console.debug("Fetching params", params);
      return params;
    } else {
      return null;
    }
  }
}

// Store tracking params in local storage
export function storePaymentParams() {
  const params = parseQueryParams();
  if (params) {
    localStorage.setItem("params", JSON.stringify(params));
    console.debug("Stored params", params);
  }
}

// Get Stripe payment link with forwarded UTM parameters.
export function getPaymentLink(premium = false) {
  const baseUrl = new URL("/api/checkout", window.location.href);

  // Extract parameters from current URL
  const params = getPaymentParams() ?? {};
  const urlParams = new URLSearchParams();

  // Apply premium
  if (premium) urlParams.set("premium", "true");

  // Add params to urlParams
  Object.entries(params ?? {}).forEach(([key, value]) =>
    urlParams.set(key, value)
  );

  // Append UTM parameters to Stripe URL if any exist
  if (urlParams.toString()) {
    return `${baseUrl}?${urlParams.toString()}`;
  } else {
    return baseUrl;
  }
}
