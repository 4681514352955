import de from "@/locales/de/index";
import en from "@/locales/en/index";
import es from "@/locales/es/index";
import fr from "@/locales/fr/index";
import pl from "@/locales/pl/index";
import nl from "@/locales/nl/index";
import pt from "@/locales/pt/index";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { createI18nStore } from "svelte-i18next";

const defaultNS = "translation";
const resources = {
  en,
  de,
  es,
  fr,
  pl,
  nl,
  pt,
};

i18next.use(LanguageDetector).init({
  supportedLngs: ["en", "de", "es", "fr", "pl", "nl", "pt"],
  ns: ["translation"],
  defaultNS,
  resources,
  interpolation: {
    escapeValue: false, // not needed for svelte as it escapes by default
  },
});

const i18n = createI18nStore(i18next);
export default i18n;
