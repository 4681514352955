export const services = {
  // Direct URL services
  xvideos: { url: "https://xvideos.com/?k=", premium: false },
  pornhub: { url: "https://pornhub.org/video/search?search=", premium: false },
  xhamster: { url: "https://xhamster.com/search/", premium: false },
  xnxx: { url: "https://www.xnxx.com/search/", premium: false },
  eporner: { url: "https://www.eporner.com/search/", premium: false }, 
  spankbang: { paywall: true, premium: true },
  youporn: { paywall: true, premium: true },
  redtube: { paywall: true, premium: true },
  dinotube: { url: "https://www.dinotube.com/search/a/", premium: false },
  pornone: { paywall: true, premium: true },
  tubesafari: { paywall: true, premium: true },
  beeg: { paywall: true, premium: true },
  ok: { paywall: true, premium: true },
  porn300: { paywall: true, premium: true },
  fuq: { paywall: true, premium: true },
  ixxx: { paywall: true, premium: true },
  txxx: { paywall: true, premium: true },
  pornzog: { paywall: true, premium: true },
  hclips: { paywall: true, premium: true },
  tubegalore: { paywall: true, premium: true },
  zbporn: { paywall: true, premium: true },
  sexvid: { paywall: true, premium: true },
  pornkai: { paywall: true, premium: true },
  sunporno: { paywall: true, premium: true },
  tube8: { paywall: true, premium: true },
  gotporn: { paywall: true, premium: true },
  pornhat: { paywall: true, premium: true },
  tiava: { paywall: true, premium: true },
  perfectgirls: { paywall: true, premium: true },
  letmejerk: { paywall: true, premium: true },
  tubev: { paywall: true, premium: true },
  iwank: { paywall: true, premium: true },
  "4tube": { paywall: true, premium: true },
  alohatube: { paywall: true, premium: true },
  hello: { paywall: true, premium: true },
  cumlouder: { paywall: true, premium: true },
  hdxnxx: { paywall: true, premium: true },
  xcafe: { paywall: true, premium: true },
  megatube: { paywall: true, premium: true },
  porn: { paywall: true, premium: true },
  porntube: { paywall: true, premium: true },
  shameless: { paywall: true, premium: true },
  xbabe: { paywall: true, premium: true },
  porndroids: { paywall: true, premium: true },
  pornid: { paywall: true, premium: true },
  pussyspace: { paywall: true, premium: true },
  hdzog: { paywall: true, premium: true },
  hellporno: { paywall: true, premium: true },
  sex3: { paywall: true, premium: true },
  porndig: { paywall: true, premium: true },
  youjizz: { paywall: true, premium: true },
  motherless: { paywall: true, premium: true },
  drtuber: { paywall: true, premium: true },
  nuvid: { paywall: true, premium: true },
  pornerbros: { paywall: true, premium: true },
  alphaporno: { paywall: true, premium: true },
  pornoxo: { paywall: true, premium: true },
  tnaflix: { paywall: true, premium: true },
  empflix: { paywall: true, premium: true },
  alotporn: { paywall: true, premium: true },
  cliphunter: { paywall: true, premium: true },
  xxxbunker: { paywall: true, premium: true },
  pornhost: { paywall: true, premium: true },
  fux: { paywall: true, premium: true },
  kporno: { paywall: true, premium: true },
  definebabe: { paywall: true, premium: true },
  babestube: { paywall: true, premium: true },
  pornotube: { paywall: true, premium: true },
  nonktube: { paywall: true, premium: true },
  boysfood: { paywall: true, premium: true },
  moviefap: { paywall: true, premium: true },
  hdpornstar: { paywall: true, premium: true },
  al4a: { paywall: true, premium: true },
  stileproject: { paywall: true, premium: true },
  lesbianpornvideos: { paywall: true, premium: true },
  sexoasis: { paywall: true, premium: true },
  pornomovies: { paywall: true, premium: true },
  babestube: { paywall: true, premium: true },
  tubixe: { paywall: true, premium: true },
  desihoes: { paywall: true, premium: true },
  "141tube": { paywall: true, premium: true },
  pornheed: { paywall: true, premium: true },
  dirtydirtyangels: { paywall: true, premium: true },
  sexkate: { paywall: true, premium: true },
  nextdoordolls: { paywall: true, premium: true },
  yourpornjizz: { paywall: true, premium: true },
  yteenporn: { paywall: true, premium: true },
  maxjizztube: { paywall: true, premium: true },
  xclip: { paywall: true, premium: true },
  freepornfox: { paywall: true, premium: true },
  dutchxtube: { paywall: true, premium: true },
  anysex: { paywall: true, premium: true },
}