<script>
  import { createEventDispatcher } from "svelte";
  import { fade } from "svelte/transition";
  const dispatch = createEventDispatcher();

  export let showModal = false;

  function closeModal() {
    showModal = false;
    dispatch("close");
  }
</script>

{#if showModal}
  <div class="modal-overlay" transition:fade={{ duration: 300 }}>
    <div class="modal">
      <button class="close-button" on:click={closeModal}>×</button>
      <div class="header">
        <svg
          class="header-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"
          ></path>
          <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
          <line x1="12" y1="22.08" x2="12" y2="12"></line>
        </svg>
        <h2>
          Find what you need <br /><span class="highlight">very fast</span>
        </h2>
      </div>

      <div class="content">
        <div class="feature-card">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"
            ></path>
          </svg>
          <p>
            Find lost gems, uncover new, unseen content and even premium content
            for free on other adult sites.
          </p>
        </div>

        <div class="features-grid">
          <div class="feature">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
              ></path>
              <polyline points="22,6 12,13 2,6"></polyline>
            </svg>
            <strong>Comprehensive Listings</strong>
            <p>We offer the largest collection of verified content sites.</p>
          </div>

          <div class="feature">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
              <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
            </svg>
            <strong>Secure</strong>
            <p>
              Every site is checked for data security. We use Stripe for safe
              payments.
            </p>
          </div>

          <div class="feature">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
              <polyline points="2 17 12 22 22 17"></polyline>
              <polyline points="2 12 12 17 22 12"></polyline>
            </svg>
            <strong>Original Content</strong>
            <p>
              Combined, all listed sites have millions of daily uploads. From
              private collectors to professional studios.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(8px);
    z-index: 1000;
  }

  .modal {
    background: linear-gradient(145deg, #1a1a1a, #2a2a2a);
    padding: 2.5em;
    border-radius: 16px;
    max-width: 600px;
    width: 90%;
    max-height: 90vh; /* Add max-height */
    overflow-y: auto; /* Enable scrolling */
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: #888;
    font-size: 24px;
    cursor: pointer;
    padding: 5px 12px;
    border-radius: 50%;
    transition: all 0.3s ease;
  }

  .close-button:hover {
    color: #fff;
    background: rgba(255, 62, 0, 0.2);
  }

  .header {
    text-align: center;
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header-icon {
    color: #1a73e8;
    margin-bottom: 1em;
  }

  h2 {
    color: #fff;
    margin: 0;
    font-size: 2.2em;
    font-weight: 700;
    line-height: 1.3;
  }

  .highlight {
    color: #1a73e8;
  }

  .content {
    color: #ffffff;
  }

  .feature-card {
    background: rgba(26, 115, 232, 0.1);
    padding: 1.5em;
    border-radius: 12px;
    margin-bottom: 1.5em;
    display: flex;
    align-items: center;
    gap: 1em;
    border: 1px solid rgba(26, 115, 232, 0.2);
  }

  .feature-card svg {
    color: #1a73e8;
    shrink: 0;
  }

  .feature-card p {
    margin: 0;
    text-align: left;
    color: rgba(255, 255, 255, 0.9);
  }

  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5em;
    margin-top: 2em;
  }

  .feature {
    background: rgba(255, 255, 255, 0.05);
    padding: 1.5em;
    border-radius: 12px;
    text-align: left;
    transition: transform 0.3s ease;
  }

  .feature:hover {
    transform: translateY(-5px);
  }

  .feature svg {
    color: #1a73e8;
    margin-bottom: 1em;
  }

  .feature strong {
    display: block;
    color: #fff;
    font-size: 1.1em;
    margin-bottom: 0.5em;
  }

  .feature p {
    color: rgba(255, 255, 255, 0.7);
    margin: 0;
    font-size: 0.9em;
    line-height: 1.5;
  }

  /* Add styles for mobile optimization */
  @media (max-width: 480px) {
    .modal {
      padding: 2em 1.5em;
      width: 95%;
      margin: 1em;
    }

    .close-button {
      position: fixed; /* Change to fixed */
      top: 10px;
      right: 10px;
      background: rgba(26, 26, 26, 0.9);
      z-index: 1001; /* Ensure it's above modal content */
    }

    h2 {
      font-size: 1.8em;
      margin-top: 1em; /* Add space for fixed close button */
    }

    .features-grid {
      grid-template-columns: 1fr; /* Single column on mobile */
    }

    .feature-card {
      padding: 1.2em;
    }
  }
</style>
