<script>
  export let onClose = () => {};
</script>

<div class="modal-overlay" on:click={onClose}>
  <div class="modal-content" on:click|stopPropagation>
    <button class="close-button" on:click={onClose}>×</button>
    <h2>Privacy Policy</h2>
    <div class="modal-body">
      <h3>1. Introduction</h3>
      <p>
        everymov.com ("we," "our," or "us") is committed to protecting your
        privacy. This Privacy Policy explains how we collect, use, and safeguard
        your personal information. By using our Service, you consent to the
        practices outlined in this Policy.
      </p>

      <h3>2. Information We Collect</h3>
      <p>
        We only collect your email address when you provide it during the
        purchase process. This email is necessary to grant you access to our
        Service by sending you an access link. No other personal information is
        required or collected.
      </p>

      <h3>3. How We Use Your Information</h3>
      <p>
        The email address you provide is used solely to deliver access to our
        Service and to communicate with you regarding account-related matters,
        if necessary.
      </p>

      <h3>4. No Data Sharing or Sale</h3>
      <p>
        We do not share, sell, rent, or disclose your personal information to
        third parties. Your email address is kept private and used exclusively
        for the purpose of granting access to the Service.
      </p>

      <h3>5. Data Security</h3>
      <p>
        We take reasonable steps to protect the information we store. While we
        implement standard security measures, no method of data transmission
        over the internet or electronic storage is completely secure, and we
        cannot guarantee absolute security.
      </p>

      <h3>6. Data Retention</h3>
      <p>
        We retain your email address only as long as necessary to provide access
        to the Service. If you request account deletion, your email and any
        associated data will be permanently removed from our records.
      </p>

      <h3>7. Account Deletion</h3>
      <p>
        You may request the deletion of your account and data by contacting us
        at <a href="mailto:4e34cab6f9114290a1302a34175d87bb@domainsbyproxy.com"
          >mail</a
        >. Upon receipt of your request, we will promptly delete your
        information, including the access link and email address.
      </p>

      <h3>8. Changes to This Policy</h3>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        effective upon posting the updated Policy on our website. Continued use
        of the Service after any changes indicates acceptance of the modified
        Policy.
      </p>

      <h3>9. Contact Us</h3>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at <a
          href="mailto:4e34cab6f9114290a1302a34175d87bb@domainsbyproxy.com"
          >mail</a
        >.
      </p>
    </div>
  </div>
</div>

<style>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    background: white;
    padding: 2rem;
    border-radius: 4px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
  }

  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
  }

  .modal-body {
    margin-top: 1rem;
  }
</style>
