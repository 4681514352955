<script>
  let title = "EveryMov DEMO";
  let subtitle = "";
  let searchInput = "Mia Khalifa";
  let showError = false;

  const sites = {
    eporner: { url: "https://www.eporner.com/search/" },
    xhamster: { url: "https://xhamster.com/search/" },
    xvideos: { url: "https://xvideos.com/?k=" },
    safari: { url: "https://tubesafari.com/search/" },
    aloha: { url: "https://www.alohatube.com/" },
  };

  const handleButtonClick = (site) => {
    if (!searchInput.trim()) {
      showError = true;
      setTimeout(() => {
        showError = false;
      }, 3000);
      return;
    }

    const searchTerm = encodeURIComponent(searchInput.trim());
    const url = sites[site].url + searchTerm;
    window.open(url, "_blank");
  };
</script>

<main class="flex flex-1 text-white px-4 py-8 min-h-screen items-center">
  <div class="container mx-auto max-w-4xl">
    <!-- Header Section -->
    <div class="text-center space-y-4 mb-16">
      <h1 class="text-4xl md:text-6xl font-bold tracking-tight">
        {title}
      </h1>
      <p class="text-lg md:text-xl text-gray-400 max-w-2xl mx-auto">
        {subtitle}
      </p>
    </div>

    <!-- Search input section -->
    <div class="mt-12">
      <div class="max-w-xl mx-auto">
        <div class="flex gap-2 relative">
          <input
            type="text"
            bind:value={searchInput}
            placeholder=""
            class="flex-1 px-4 py-3 pr-12 bg-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-white typing-animation {showError
              ? 'shake border-red-500 border'
              : ''}"
          />
          <svg
            class="w-5 h-5 text-gray-400 absolute right-4 top-1/2 transform -translate-y-1/2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>

        <div class="pt-4 pl-4 text-white/50">
          Open <strong>'{searchInput}'</strong> in:
        </div>

        <div class="flex justify-center gap-4 mt-4 flex-wrap">
          <button
            on:click={() => handleButtonClick("xvideos")}
            class="px-6 py-3 rounded-xl font-medium transition-all transform hover:scale-105 shadow-lg {!searchInput.trim()
              ? 'bg-gray-600 cursor-not-allowed opacity-50'
              : 'bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700 hover:shadow-red-500/30'}"
            disabled={!searchInput.trim()}
          >
            xvideos.com
          </button>
          <button
            on:click={() => handleButtonClick("xhamster")}
            class="px-6 py-3 rounded-xl font-medium transition-all transform hover:scale-105 shadow-lg {!searchInput.trim()
              ? 'bg-gray-600 cursor-not-allowed opacity-50'
              : 'bg-gradient-to-r from-orange-500 to-orange-600 hover:from-orange-600 hover:to-orange-700 hover:shadow-orange-500/30'}"
            disabled={!searchInput.trim()}
          >
            xhamster.com
          </button>
          <button
            on:click={() => handleButtonClick("eporner")}
            class="px-6 py-3 rounded-xl font-medium transition-all transform hover:scale-105 shadow-lg {!searchInput.trim()
              ? 'bg-gray-600 cursor-not-allowed opacity-50'
              : 'bg-gradient-to-r from-purple-500 to-purple-600 hover:from-purple-600 hover:to-purple-700 hover:shadow-purple-500/30'}"
            disabled={!searchInput.trim()}
          >
            eporner.com
          </button>
          <button
            on:click={() => handleButtonClick("safari")}
            class="px-6 py-3 rounded-xl font-medium transition-all transform hover:scale-105 shadow-lg {!searchInput.trim()
              ? 'bg-gray-600 cursor-not-allowed opacity-50'
              : 'bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 hover:shadow-green-500/30'}"
            disabled={!searchInput.trim()}
          >
            tubesafari.com
          </button>
          <button
            on:click={() => handleButtonClick("aloha")}
            class="px-6 py-3 rounded-xl font-medium transition-all transform hover:scale-105 shadow-lg {!searchInput.trim()
              ? 'bg-gray-600 cursor-not-allowed opacity-50'
              : 'bg-gradient-to-r from-cyan-500 to-cyan-600 hover:from-cyan-600 hover:to-cyan-700 hover:shadow-cyan-500/30'}"
            disabled={!searchInput.trim()}
          >
            alohatube.com
          </button>
        </div>

        <!-- Arrow indicator -->
        <!-- <div
          class="text-blue-400 animate-bounce flex flex-col items-center mt-8"
        >
          <span class="text-lg">Click any button!</span>
          <svg
            class="w-6 h-6 rotate-180"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 14l-7 7m0 0l-7-7m7 7V3"
            />
          </svg>
        </div> -->
      </div>
    </div>
  </div>
</main>

<style>
  .shake {
    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>
