<script>
  import PrivacyModal from "./PrivacyModal.svelte";
  export let showCookieBanner;
  let showPrivacyModal = false;

  function acceptCookies() {
    localStorage.setItem("cookiesAccepted", "true");
    showCookieBanner = false;
  }

  function declineCookies() {
    showCookieBanner = false;
  }
</script>

{#if showCookieBanner}
  <div class="cookie-banner">
    <p>
      This website uses cookies to ensure you get the best experience. Read our <a
        href="#"
        on:click|preventDefault={() => (showPrivacyModal = true)}
        >Privacy Policy</a
      > to learn more.
    </p>
    <div class="button-container">
      <button class="high-contrast" on:click={acceptCookies}>Accept</button>
      <button class="low-contrast" on:click={declineCookies}>Decline</button>
    </div>
  </div>
{/if}

{#if showPrivacyModal}
  <PrivacyModal onClose={() => (showPrivacyModal = false)} />
{/if}

<style>
  .cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #333;
    color: white;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    z-index: 1000;
  }

  .cookie-banner p {
    margin: 0;
  }

  .cookie-banner a {
    color: #ff3e00;
    text-decoration: none;
  }

  .cookie-banner a:hover {
    text-decoration: underline;
  }
</style>
