<script>
  import i18n from "@/lib/i18n";
  $: t = $i18n.getFixedT(null, null, "socialProof");

  $: testimonials = [
    {
      name: t("name1"),
      text: t("text1"),
      stars: 5,
    },
    {
      name: t("name2"),
      text: t("text2"),
      stars: 5,
    },
    {
      name: t("name3"),
      text: t("text4"),
      stars: 5,
    },
  ];
</script>

<main class="flex flex-1 text-white px-4 py-8 items-center bg-black">
  <div class="container mx-auto max-w-6xl text-center">
    <!-- Testimonials Grid -->
    <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mt-12">
      {#each testimonials as testimonial}
        <div class="p-6">
          <!-- Testimonial Text -->
          <p class="text-gray-400 text-xl mb-3 italic">"{testimonial.text}"</p>

          <!-- Author Info -->
          <div class="mt-auto">
            <p class="text-sm text-gray-500">— {testimonial.name}</p>
          </div>
        </div>
      {/each}
    </div>
  </div>
</main>
