<script>
  import Footer from "../Footer.svelte";
  import Header from "./Components/Header.svelte";
  import Ending from "./Components/Ending.svelte";
  import Title from "./Components/Title.svelte";
  import Hero1 from "./Components/Hero1.svelte";
  import Hero2 from "./Components/Hero2.svelte";
  import Hero3 from "./Components/Hero3.svelte";
  import Pay from "./Components/Pay.svelte";
  import Pay2 from "./Components/Pay2.svelte";
  import Questions from "./Components/Questions.svelte";
  import Testimonial from "./Components/Testimonial.svelte";
  import Testimonials from "./Components/Testimonials.svelte";
  import Cookie from "./Components/Cookie.svelte";
  import NewTitle from "./Components/NewTitle.svelte";
  import Arguments from "./Components/Arguments.svelte";
  import Why from "./Components/Why.svelte";
  import Problem from "./Components/Problem.svelte";
  import { onMount } from "svelte";
  import { storePaymentParams } from "@/lib/stripe";
  import SocialProof from "./LP2/SocialProof.svelte";
  import Arguments2 from "./Components/Arguments2.svelte";

  // Store params for payment link on page load.
  onMount(storePaymentParams);
</script>

<div>
  <Header />
  <!-- <Title /> -->
  <!-- <NewTitle /> -->

  <!-- <Problem /> -->
  <!-- <Arguments /> -->
  <!-- <Arguments2 /> -->

  <!-- <Why /> -->

  <div id="pricing-section">
    <Pay />
  </div>
  <SocialProof />
  <!-- <Ending /> -->
  <div id="questions-section">
    <Questions />
  </div>
  <!-- <Hero1 /> -->
  <!-- <Hero2 />
  <Hero3 />
  -->

  <!-- <div id="testimonial-section">
    <Testimonials />
  </div> -->
  <Ending />
  <Footer />
  <Cookie />
  <!-- Add the cookie banner here -->
</div>
