<script>
  import i18n from "@/lib/i18n";
  $: t = $i18n.getFixedT(null, null, "questions");
</script>

<section class="hero">
  <div class="container">
    <h1 class="title">{t("title")}</h1>
    <details class="rounded-corners">
      <summary>{t("howItWorks.question")}</summary>
      <div>
        <div style="display: flex;">
          <img src="/gif5.GIF" alt="usually gif here..." class="info-gif" />
        </div>
        <strong>{t("howItWorks.step1")}</strong><br />
        {t("howItWorks.step1Text")}
        <br /><br />
        <strong>{t("howItWorks.step2")}</strong><br />
        {t("howItWorks.step2Text")}
        <br /><br />
        <strong>{t("howItWorks.step3")}</strong><br />
        {t("howItWorks.step3Text")}
        <br /><br />
        <br />
        {t("howItWorks.additional")}
      </div>
    </details>
    <details class="rounded-corners">
      <summary>{t("personalAccess.question")}</summary>
      <div>
        {t("personalAccess.answer")}
      </div>
    </details>
    <details class="rounded-corners">
      <summary>{t("addMoreSites.question")}</summary>
      <div>
        {t("addMoreSites.answer")}
      </div>
    </details>

    <details class="rounded-corners">
      <summary>{t("difference.question")}</summary>
      <div>{@html t("difference.answer")}</div>
    </details>
    <!-- <details class="rounded-corners">
      <summary>{t("sites.question")}</summary>
      <div>
        {t("sites.answer")}
        <div style="display: flex; justify-content: center;">
          <img
            src="/header4.png"
            alt="Sites comparison"
            style="max-width: 300px;"
          />
        </div>
      </div>
    </details> -->
    <details class="rounded-corners">
      <summary>{t("paymentSafety.question")}</summary>
      <div>
        {t("paymentSafety.answer")}
      </div>
    </details>
    <!-- <details class="rounded-corners">
      <summary>{t("refund.question")}</summary>
      <div>
        {t("refund.answer")}
        <a
          href="mailto:4e34cab6f9114290a1302a34175d87bb@domainsbyproxy.com"
          style="color: #ffd700;"
        >
          here
        </a>.
        <br /><br />
        {t("refund.additional")}
      </div>
    </details> -->

    <details class="rounded-corners">
      <summary>{t("sub.question")}</summary>
      <div>
        {t("sub.answer")}
      </div>
    </details>
    <!-- <details class="rounded-corners">
      <summary>{t("paypal.question")}</summary>
      <div>{t("paypal.answer")}</div>
    </details> -->
    <!-- <details class="rounded-corners">
      <summary>{t("access.question")}</summary>
      <div>
        {t("access.answer")}
      </div>
    </details>

    <details class="rounded-corners">
      <summary>{t("registration.question")}</summary>
      <div>{t("registration.answer")}</div>
    </details> -->
  </div>
</section>

<style>
  .rounded-corners {
    border-radius: 12px; /* Slightly more rounded for a modern look */
  }
  .info-gif {
    max-width: 200px;
    border-radius: 4px;
  }

  .hero {
    background: #1a1a1a; /* Gradient for a modern touch */
    min-height: 100vh;
    display: flex;
    flex-direction: column; /* Align items to the top */
    align-items: center;
    justify-content: flex-start; /* Align items to the top */
    color: #f5f5f5; /* Lighter gray for better contrast */
    padding-top: 80px; /* Added top padding */
  }

  .container {
    max-width: 1000px; /* Slightly narrower for a more focused look */
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
  }

  .title {
    font-size: 3.5rem; /* Larger font for a more impactful title */
    font-weight: 700; /* Bolder font weight */
    margin-bottom: 48px; /* Reduced margin for tighter spacing */
    line-height: 1.1; /* Tighter line height */
    color: #e0e0e0; /* Less white for a softer look */
  }

  details {
    background-color: #1a1a1a; /* Darker background for contrast */
    color: #b0b0b0; /* Softer gray for text */
    font-size: 1.2rem; /* Reduced font size */
    margin-bottom: 10px; /* Reduced space between items */
    overflow: hidden;
    border: 1px solid #222; /* Darker border for definition */
    transition: background-color 0.3s ease; /* Smooth transition for hover */
  }

  details:hover {
    background-color: #3a3a3a; /* Slightly lighter on hover */
  }

  summary {
    padding: 0.8em 1.5rem;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center; /* Align items vertically */
    cursor: pointer;
    text-align: left;
    font-size: 1.4rem; /* Reduced font size */
    color: #c0c0c0; /* Less white for a softer look */
    transition: color 0.3s ease;
  }

  summary:hover {
    color: #ffd700; /* Light gray color on hover for interactivity */
  }

  summary::-webkit-details-marker {
    display: none;
  }

  summary:after {
    content: "\002B";
    transition: transform 0.3s ease;
    transform-origin: center;
    shrink: 0; /* Prevent icon from shrinking */
  }

  details[open] summary {
    border-bottom: 1px solid #333; /* Darker border for open state */
    margin-bottom: 0.5em;
  }

  details[open] summary:after {
    content: "\00D7";
    transform: rotate(45deg);
    transform-origin: center;
  }
  details div {
    max-height: 0;
    transition:
      max-height 0.5s ease,
      padding 0.5s ease;
    padding: 0 1.2em;
    text-align: left;
    font-size: 1.2rem; /* Reduced font size */
    color: #c0c0c0;
  }

  details[open] div {
    max-height: 100vh;
    padding: 0.8em 1.2em; /* More padding for open state */
  }

  @media (max-width: 600px) {
    .hero {
      padding: 80px 0; /* More vertical padding for mobile */
    }
    details {
      margin-bottom: 14px; /* Reduced spacing on mobile */
    }
    summary {
      font-size: 1.2rem; /* Decreased font size on mobile */
    }
  }
</style>
