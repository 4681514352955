<script>
  import { services } from "../../services";

  let title = "Unlock the TOP 100 sites";
  let subtitle = "+500 Million individual videos";
  let showAll = false;
  let searchQuery = ""; // Add search query state

  // Convert services object to array, add domain info, and get base URL
  const sitesList = Object.entries(services).map(([id, site]) => {
    const url = new URL(site.url);
    return {
      id,
      ...site,
      domain: url.hostname.replace("www.", ""),
      baseUrl: `${url.protocol}//${url.hostname}`,
    };
  });

  const initialLimit = 12;

  // Update the displayedSites reactive statement to include search filtering
  $: filteredSites = sitesList.filter((site) =>
    site.domain.toLowerCase().includes(searchQuery.toLowerCase())
  );
  $: displayedSites = showAll
    ? filteredSites
    : filteredSites.slice(0, initialLimit);
</script>

<main class="flex flex-1 text-white px-4 py-8 min-h-screen">
  <div class="container mx-auto max-w-6xl">
    <!-- Header Section -->
    <div class="text-center space-y-4 mb-16">
      <h1 class="text-4xl md:text-6xl font-bold tracking-tight">
        {title}
      </h1>
      <p class="text-lg md:text-xl text-gray-400 max-w-2xl mx-auto">
        {subtitle}
      </p>
    </div>

    <!-- Add Search Field -->
    <div class="mb-8">
      <div class="relative max-w-md mx-auto">
        <input
          type="text"
          bind:value={searchQuery}
          placeholder="Search sites..."
          class="w-full px-4 py-2 pr-12 rounded-lg bg-gray-800 border border-gray-700 focus:outline-none focus:border-blue-500"
        />
        <svg
          class="w-5 h-5 text-gray-400 absolute right-4 top-1/2 transform -translate-y-1/2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div>
    </div>

    <!-- Sites Grid -->
    <div
      class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-4"
    >
      {#each displayedSites as site}
        <a
          href={site.baseUrl}
          target="_blank"
          rel="noopener noreferrer"
          class="p-3 md:p-4 hover:bg-gray-800/50 rounded-lg transition-all duration-200"
        >
          <div class="flex items-center">
            <span
              class="text-blue-400 hover:text-blue-300 underline text-sm md:text-base"
            >
              {site.domain}
            </span>
          </div>
        </a>
      {/each}
    </div>

    <!-- Update Show More Button -->
    {#if !showAll && filteredSites.length > initialLimit}
      <div class="text-center mt-8">
        <button
          on:click={() => (showAll = true)}
          class="px-6 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors"
        >
          Show All
        </button>
      </div>
    {/if}
  </div>
</main>
