<script>
  import { Icon, Play } from "svelte-hero-icons";
  import { onMount } from "svelte";
  import PricingCard from "./PricingCard.svelte";
  import i18n from "@/lib/i18n";
  $: t = $i18n.getFixedT(null, null, "ending");

  import { getPaymentLink } from "../../lib/stripe";

  let customerCount = 3;
  let visitorCount = 30;

  onMount(() => {
    const updateCustomerCount = () => {
      if (customerCount > 1) {
        customerCount -= 1;
      }
    };
    const updateVisitorCount = () => {
      // Add visitorCount update logic
      visitorCount = Math.floor(Math.random() * (33 - 26 + 1)) + 25;
    };

    const customerInterval = setInterval(updateCustomerCount, 30000);
    const visitorInterval = setInterval(updateVisitorCount, 3000); // Add visitorInterval
    return () => {
      clearInterval(customerInterval);
      clearInterval(visitorInterval); // Clear visitorInterval
    };
  });
  // Add countdown variables
  let countdown = 120; // Countdown starts at two minutes (120 seconds)
  let minutes = Math.floor(countdown / 60);
  let seconds = countdown % 60;

  onMount(() => {
    // Get saved countdown or use default
    countdown = 120;
    minutes = Math.floor(countdown / 60);
    seconds = countdown % 60;

    const updateCountdown = () => {
      if (countdown > 0) {
        countdown -= 1;
        minutes = Math.floor(countdown / 60);
        seconds = countdown % 60;
        localStorage.setItem("countdown", countdown.toString());
      }
    };

    const countdownInterval = setInterval(updateCountdown, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  });

  function handleConversion(e) {
    // Prevent immediate navigation
    e.preventDefault();

    // Get the href from the clicked element
    const href = e.currentTarget.href;

    // Send destination URL to PostHog
    if (window.posthog) {
      console.log("Sending PostHog event:", {
        event: "Checkout Click",
        properties: { destination: href },
      });
      window.posthog.capture("Checkout Click", {
        destination: href,
      });
    } else {
      console.log("PostHog not initialized");
    }

    // Trigger the Traffic Factory conversion
    if (window.tf_goal_473365127607badd19ea3b67cd5e490e) {
      window.tf_goal_473365127607badd19ea3b67cd5e490e.fire();
    }

    // Navigate after a small delay to ensure the conversion is tracked
    setTimeout(() => {
      window.location.href = href;
    }, 100);
  }
</script>

<section class="tiny-hero">
  <div class="tiny-container">
    <h1 class="tiny-title">{t("title")}</h1>
    <p class="tiny-subtitle">{t("subtitle")}</p>
    <div class="button-wrapper">
      <a
        href={getPaymentLink()}
        class="cta-button plausible-event-name=action"
        on:click={handleConversion}
      >
        <Icon
          src={Play}
          style="margin-right: 8px; width: 32px; height: 32px; font-weight: bold; fill: #000;"
        /> <span style="font-size: 1.25rem;">$5,99 USD</span>
        <div style="text-decoration: line-through;">$18,99</div>
      </a>
    </div>
    <p class="special-offer">
      <span class="offer-text">
        {t("time")}
        {minutes} min {seconds} sec
      </span>
    </p>
  </div>
</section>

<style>
  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }

  .cta-button {
    background-color: #ffd700;
    color: #2c2c2c;
    font-weight: bold;
    padding: 16px 32px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition:
      background-color 0.3s,
      transform 0.3s;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-decoration: none;
  }
  .cta-button:hover {
    background-color: #e5c100;
    transform: scale(1.05);
  }

  .special-offer {
    font-size: 1rem;
    margin-top: 16px;
    color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }

  .offer-text {
    color: #b7b7b7;
    animation: subtle-fade 3s infinite;
    margin-right: 5px;
  }
  .tiny-hero {
    background-color: #000;
    min-height: 70vh; /* Reduced height */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 20px 0; /* Added responsive padding top and bottom */
  }
  .tiny-container {
    max-width: 600px; /* Reduced width */
    margin: 0 auto;
    padding: 0 8px; /* Reduced padding */
    text-align: center;
  }
  .tiny-title {
    font-size: 4rem; /* Increased font size for tablet and desktop */
    font-weight: bold;
    margin-top: 80px;
    color: #d0d0d0; /* Less white for a softer look */
    line-height: 1.2; /* Decreased line height */
  }
  .tiny-subtitle {
    font-size: 0.875rem; /* Reduced font size */
    margin-bottom: 16px; /* Reduced margin */
    color: gray; /* Use gray color */
    margin-bottom: 32px;
  }
  .special-offer {
    font-size: 1rem;
    margin-top: 16px;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .offer-text {
    animation: subtle-fade 3s infinite;
    margin-right: 5px;
  }
  .customer-count {
    margin-left: 5px;
    margin-right: 5px;
  }
  .visitor-count {
    font-size: 1rem;
    margin-top: 8px;
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .live-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: green;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: middle;
    animation: pulse 2s infinite;
    margin-left: 5px;
  }

  @keyframes subtle-fade {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
  }
  @media (max-width: 600px) {
    .tiny-title {
      font-size: 3rem; /* Increased font size for mobile */
      line-height: 1.2; /* Decreased line height for mobile */
    }
    .tiny-subtitle {
      font-size: 1rem; /* Increased font size for mobile */
      padding-bottom: 16px; /* Added padding beneath */
      padding-top: 16px; /* Added padding beneath */
    }
    .cta-button span {
      font-size: 1.5rem; /* Increased font size for mobile */
    }
    .tiny-hero {
      padding: 20px 20px 60px 20px; /* Adjusted padding for mobile with extra padding to bottom */
    }
  }
</style>
