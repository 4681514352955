<script>
  import { Icon, Play } from "svelte-hero-icons";
  import { onMount } from "svelte";
  import { getPaymentLink } from "../../lib/stripe";
  import i18n from "@/lib/i18n";

  $: t = $i18n.getFixedT(null, null, "pricingCard");

  let countdown = 120; // Countdown starts at two minutes (120 seconds)
  let minutes = Math.floor(countdown / 60);
  let seconds = countdown % 60;

  onMount(() => {
    // Get saved countdown or use default
    countdown = 120;
    minutes = Math.floor(countdown / 60);
    seconds = countdown % 60;

    const updateCountdown = () => {
      if (countdown > 0) {
        countdown -= 1;
        minutes = Math.floor(countdown / 60);
        seconds = countdown % 60;
        // Save to localStorage
        localStorage.setItem("countdown", countdown.toString());
      }
    };

    const countdownInterval = setInterval(updateCountdown, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  });

  export let title = "One Time Purchase";
  export let price = "Lifetime Access";
  export let oldPrice = "$24,99";

  function handleConversion(e) {
    // Prevent immediate navigation
    e.preventDefault();

    // Get the href from the clicked element
    const href = e.currentTarget.href;

    // Send destination URL to PostHog
    if (window.posthog) {
      console.log("Sending PostHog event:", {
        event: "Checkout Click",
        properties: { destination: href },
      });
      window.posthog.capture("Checkout Click", {
        destination: href,
      });
    } else {
      console.log("PostHog not initialized");
    }

    // Trigger the Traffic Factory conversion
    if (window.tf_goal_473365127607badd19ea3b67cd5e490e) {
      window.tf_goal_473365127607badd19ea3b67cd5e490e.fire();
    }

    // Navigate after a small delay to ensure the conversion is tracked
    setTimeout(() => {
      window.location.href = href;
    }, 100);
  }
</script>

<div class="pricing-cards-container">
  <div class="pricing-card">
    <!-- <h2 class="plan-title">{t("basicTitle")}</h2> -->
    <p class="plan-price">
      {t("basicSubtitle")}
      <!-- <span class="old-price">$18,99</span> -->
      <span class="currency"></span>
    </p>
    <ul class="plan-features">
      <li>✔&nbsp; {t("features.basic1")}</li>
      <li>✔&nbsp; {t("features.basic2")}</li>
      <li>✔&nbsp; {t("features.basic3")}</li>
      <!-- <li>✔&nbsp; 60 Days Money Back</li> -->
    </ul>
    <a
      href={getPaymentLink()}
      class="select-plan-button plausible-event-name=action"
      onclick={handleConversion}
    >
      <Icon src={Play} style="width: 24px; height: 24px; fill: #000;" />
      <span class="animated-price">{t("basicPrice")}</span>
      <div style="text-decoration: line-through; font-weight: normal;">
        {t("basicOldPrice")}
      </div>
    </a>
    <p class="cta-note">
      {t("ctaExpires")}
      <strong>{minutes} min {seconds} sec</strong>
    </p>
  </div>
  <div class="pricing-card most-chosen">
    <div class="popular-badge">{t("mostPopular")}</div>
    <!-- <h2 class="plan-title">{t("proTitle")}</h2> -->
    <p class="plan-price">
      <!-- <span class="old-price">{oldPrice}</span> -->
      {t("proSubtitle")}
      <!-- <span class="currency"> USD</span> -->
    </p>
    <ul class="plan-features">
      <li>✔&nbsp; {t("features.pro1")}</li>
      <li>✔&nbsp; {t("features.pro2")}</li>
      <li>✔&nbsp; {t("features.pro3")}</li>
      <!-- <li>✔&nbsp; Mobile, Tablet & Desktop access</li>
      <li>✔&nbsp; Perfect for users who are willing to pay for content</li> -->
      <!-- <li style="color: #666666;">✘&nbsp; Premium customer support</li>
      <li style="color: #666666;">✘&nbsp; Early access to beta features</li> -->
    </ul>
    <a
      href={getPaymentLink(true)}
      class="select-plan-button plausible-event-name=action"
    >
      <Icon src={Play} style="width: 24px; height: 24px; fill: #000;" />
      <span class="animated-price">{t("proPrice")}</span>
      <div style="text-decoration: line-through; font-weight: normal;">
        {t("proOldPrice")}
      </div>
    </a>
    <p class="cta-note">
      <!-- One time payment. Start immediately! -->
      {t("ctaExpires")}
      <strong>{minutes} min {seconds} sec</strong>
    </p>
  </div>
</div>

<style>
  .pricing-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px; /* Increased gap for more spacing */
    padding: 10px; /* Increased padding for more spacing */
    padding-top: 60px; /* Added padding top */
  }
  .pricing-card {
    background-color: #2c2c2c;
    border: 1px solid #444;
    border-radius: 12px;
    padding: 24px; /* Increased padding for larger content area */
    width: 480px; /* Increased width for larger cards */
    text-align: center;
    color: #e0e0e0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5); /* Slightly larger shadow */
    position: relative;
    transition:
      transform 0.3s ease,
      box-shadow 0.3s ease;
  }
  .pricing-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7); /* Larger shadow on hover */
  }
  .most-chosen {
    border: 2px solid #ffd700;
  }
  .popular-badge {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffd700;
    color: #2c2c2c;
    padding: 5px 10px;
    border-radius: 12px;
    font-weight: bold;
    font-size: 1rem; /* Slightly larger font size */
  }
  .plan-title {
    font-size: 1.5rem; /* Decreased font size */
    margin-bottom: 16px; /* Increased margin */
    color: #cccccc; /* Less white */
    text-align: left; /* Align left */
    font-weight: bold; /* Added boldness to make it 'fat' */
  }
  .plan-price {
    font-size: 3rem; /* Increased font size proportionally */
    color: #cccccc;
    margin-bottom: 28px; /* Increased margin */
    text-align: left; /* Align left */
    font-weight: bold;
    line-height: 1; /* Reduced line height */
  }
  .old-price {
    font-size: 1.5rem; /* Increased font size proportionally */
    color: #888;
    text-decoration: line-through;
    margin-right: 4px; /* Increased margin */
  }
  .currency {
    font-size: 1rem; /* Increased font size proportionally */
    color: #888; /* Gray color for currency */
    margin-left: 8px; /* Small margin for spacing */
  }
  .plan-features {
    list-style: none;
    padding: 0;
    margin-bottom: 40px; /* Increased margin */
    text-align: left;
    font-weight: 500; /* Semi-bold */
  }
  .plan-features li {
    margin-bottom: 16px; /* Increased margin */
    color: #b0b0b0;
    font-size: 1.1rem; /* Increased font size for features */
  }
  .select-plan-button {
    background-color: #ffd700;
    color: #2c2c2c;
    font-weight: bold;
    padding: 16px 32px; /* Increased padding */
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition:
      background-color 0.3s,
      transform 0.3s;
    width: 100%; /* Use all horizontal available space */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; /* Space between icon and text */
    text-decoration: none; /* Remove underline from links */
  }
  .select-plan-button:hover {
    background-color: #e5c100;
    transform: scale(1.05);
  }
  .animated-price {
    /* animation: fadeInOut 1.5s infinite; */
    font-size: 1.2rem; /* Increased font size */
  }
  @keyframes fadeInOut {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  .cta-note {
    font-size: 1rem; /* Slightly larger font size */
    color: #b0b0b0;
    margin-top: 12px; /* Increased margin */
  }
  @media (max-width: 768px) {
    .pricing-card {
      width: 100%;
    }
  }
</style>
