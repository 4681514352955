<script>
  import Paywall from "../Paywall.svelte";
  import SearchBar from "../SearchBar.svelte";
  import Footer from "../Footer.svelte";
  import CookieBanner from "../CookieBanner.svelte";
  import InfoModal from "../InfoModal.svelte";
  import { services } from "../services.js";
  import "../buttons.css";
  import { onMount } from "svelte";

  let searchText = "";
  let systemMessage = "";
  let showModal = false;
  let modalMessage = "";
  let selectedService = "";
  let showCookieBanner = true;

  // Single handler for all services
  function handleService(serviceName) {
    if (!searchText) {
      systemMessage = "First, type in something";
      return;
    }

    const service = services[serviceName];

    if (service.paywall) {
      showPaywall(serviceName);
    } else {
      window.open(`${service.url}${searchText}`, "_blank");
    }
  }

  function showPaywall(service) {
    selectedService = service;
    modalMessage = "Lifetime Access – Pay Once, Enjoy Forever";
    showModal = true;
  }

  function handleModalClose() {
    showModal = false;
    selectedService = "";
  }

  function handleUpdate() {
    showPaywall("update");
  }

  let showInfoModal = false;

  function handleInfoClick() {
    navigator.clipboard.writeText("www.everymov.com/app_41234");
  }

  function handleInfoModalClose() {
    showInfoModal = false;
  }

  // Cookies
  onMount(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted) {
      showCookieBanner = false;
    }
  });
</script>

<main>
  <div>
    <h1>
      <span class="logo-text">everymov<span class="domain">.com</span></span>
    </h1>
    <div class="subtitle" on:click={handleInfoClick}>
      <svg
        class="info-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="16" x2="12" y2="12"></line>
        <line x1="12" y1="8" x2="12.01" y2="8"></line>
      </svg>
      www.everymov.com/app_41234
    </div>
  </div>

  <SearchBar bind:searchText bind:systemMessage />

  <div class="button-container">
    {#each Object.entries(services).sort( ([, a], [, b]) => (a.premium === b.premium ? 0 : a.premium ? 1 : -1) ) as [serviceName, config]}
      <button
        class={config.premium ? "low-contrast" : "high-contrast"}
        on:click={() => handleService(serviceName)}
      >
        {serviceName}
      </button>
    {/each}
  </div>

  <Paywall
    {showModal}
    {selectedService}
    {modalMessage}
    on:close={handleModalClose}
  />
</main>

<CookieBanner bind:showCookieBanner />

<InfoModal showModal={showInfoModal} on:close={handleInfoModalClose} />

<Footer />

<style>
  :global(body),
  :global(html) {
    margin: 0;
    padding: 0;
    background-color: #1a1a1a;
  }

  main {
    text-align: center;
    padding: 1em;
    max-width: 640px;
    margin: 0 auto;
    background-color: #1a1a1a;
    min-height: calc(100vh - 100px);
    color: #ffffff;
    padding-bottom: 0;
    position: relative;
  }

  .update-button {
    position: fixed;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    background: linear-gradient(135deg, #00c6ff, #0072ff);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    font-weight: 600;
    box-shadow: 0 4px 15px rgba(0, 198, 255, 0.3);
    transition: all 0.3s ease;
    z-index: 1000;
  }

  .update-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 198, 255, 0.4);
  }

  h1 {
    color: #0084ff;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: 100;
    text-shadow: 2px 2px 4px rgba(255, 62, 0, 0.2);
    padding: 0 10px;
    margin-bottom: 0;
  }

  .subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #9999997a;
    font-size: 1em;
    margin-top: 0.5em;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .subtitle:hover {
    color: #ff3e00;
  }

  .subtitle:hover .info-icon {
    color: #ff3e00;
  }

  .info-icon {
    color: #9999997a;
    transition: color 0.3s ease;
  }

  /* Add media query for smaller screens */
  @media (max-width: 480px) {
    h1 {
      font-size: 3em;
      margin: 1.5em 0 0 0; /* Adjusted margin */
    }
  }
  .logo-text {
    white-space: nowrap;
    display: inline-block;
    padding: 1em 0;
  }

  .domain {
    font-size: 0.5em;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>
