<script>
  import { onMount } from "svelte";
  import PricingCard from "./PricingCard.svelte";
  import Arguments from "./Arguments.svelte";

  import i18n from "@/lib/i18n";
  $: t = $i18n.getFixedT(null, null, "pay");

  let countdown = 300; // Countdown starts at two minutes (120 seconds)
  let minutes = Math.floor(countdown / 60);
  let seconds = countdown % 60;

  onMount(() => {
    // Get saved countdown or use default
    countdown = parseInt(localStorage.getItem("countdown")) || 180;
    minutes = Math.floor(countdown / 60);
    seconds = countdown % 60;

    const updateCountdown = () => {
      if (countdown > 0) {
        countdown -= 1;
        minutes = Math.floor(countdown / 60);
        seconds = countdown % 60;
        // Save to localStorage
        localStorage.setItem("countdown", countdown.toString());
      }
    };

    const countdownInterval = setInterval(updateCountdown, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  });
</script>

<section class="pricing-hero">
  <div class="pricing-container">
    <h1 class="pricing-title">{t("title")}</h1>
    <!-- Payment Provider Logos -->
    <div class="flex justify-center pb-8">
      <div
        class="inline-flex justify-center items-center gap-4 mt-4 px-4 py-3 rounded-lg bg-white"
      >
        <img
          src="pp.png"
          alt="PayPal"
          class="h-8 object-contain opacity-75 hover:opacity-100 transition-opacity"
        />
        <img
          src="mc.png"
          alt="Mastercard"
          class="h-8 object-contain opacity-75 hover:opacity-100 transition-opacity"
        />
        <img
          src="amex.png"
          alt="American Express"
          class="h-8 object-contain opacity-75 hover:opacity-100 transition-opacity"
        />
        <img
          src="apple.png"
          alt="Apple Pay"
          class="h-8 object-contain opacity-75 hover:opacity-100 transition-opacity"
        />
        <img
          src="google.png"
          alt="Google Pay"
          class="h-8 object-contain opacity-75 hover:opacity-100 transition-opacity"
        />
      </div>
    </div>
    <!-- <h2 class="pricing-subheader">Join 50.000 EveryMovers today.</h2> -->
    <!-- <p class="pricing-subtitle">
      <span class="special-offer">33% discount</span>
      This started as a
      <strong class="customer-count">
        {customerCount}
      </strong> subscriber.
    </p> -->
    <!-- <p class="pricing-subsubtitle">
      <span class="live-dot"></span>
      {visitorCount} visitors
    </p> -->
    <!-- <Arguments /> -->
    <PricingCard />
  </div>
</section>

<style>
  .pricing-hero {
    background-color: #1a1a1a;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #cccccc; /* Less white */
    padding: 40px 0; /* Increased vertical spacing for mobile and tablet */
  }
  /* Media query for tablets */
  @media (max-width: 768px) {
    .pricing-hero {
      padding: 60px 0; /* Increased vertical spacing for tablets */
    }
  }
  /* Media query for mobile devices */
  @media (max-width: 480px) {
    .pricing-hero {
      padding: 80px 0; /* Increased vertical spacing for mobile devices */
    }
  }
  .pricing-container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  .customer-count {
    margin-left: 5px;
    margin-right: 5px;
  }
  .pricing-title {
    font-size: 4rem;
    font-weight: bold;
    color: #cccccc; /* Less white */
    line-height: 1.2; /* Decreased line height */
    padding-left: 20px;
    padding-right: 20px;
  }
  .pricing-subheader {
    font-size: 1rem;
    font-weight: normal;
    color: #bbbbbb; /* Slightly darker for contrast */
    margin-bottom: 60px;
    margin-top: 30px;
  }
  @media (min-width: 481px) {
    .pricing-title {
      padding-left: 40px; /* Increased padding left for desktop */
      padding-right: 40px; /* Increased padding right for desktop */
    }
  }
  @media (max-width: 480px) {
    .pricing-title {
      font-size: 3rem; /* Increased font size for mobile devices */
      padding-bottom: 16px; /* Added padding left */
    }
    .pricing-subtitle {
      font-size: 1.5rem; /* Increased font size for mobile devices */
      margin-bottom: 40px; /* Added margin bottom */
    }
  }
  .pricing-subtitle {
    font-size: 1.25rem;
    margin-bottom: 16px; /* Increased bottom margin */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #cccccc; /* Less white */
  }

  .special-offer {
    color: #32cd32; /* Less bright green */
    animation: subtle-fade 3s infinite;
    margin-right: 5px;
  }
  @keyframes subtle-fade {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
  }
  .pricing-subsubtitle {
    font-size: 1rem;
    margin-bottom: 40px; /* Increased bottom margin */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999999; /* Lesser white */
  }
  @media (max-width: 480px) {
    .pricing-subsubtitle {
      font-size: 1.25rem; /* Increased font size for mobile devices */
    }
  }
  .live-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: green;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: middle;
    animation: pulse 2s infinite;
    margin-left: 5px;
  }
  @keyframes pulse {
    0%,
    100% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.5;
    }
  }
</style>
