<script>
  import { createEventDispatcher } from "svelte";
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  const dispatch = createEventDispatcher();

  export let showModal = false;
  export let modalMessage = "";
  let salesMessage = "3 sales in last 30 min";
  let visible = true;

  function closeModal() {
    showModal = false;
    dispatch("close");
  }

  function handlePayPalClick() {
    window.open("https://buy.stripe.com/5kA7tM2XyddDgIocMM", "_blank");
  }

  onMount(() => {
    const interval = setInterval(() => {
      visible = false;
      setTimeout(() => {
        salesMessage =
          salesMessage === "3 sales in last 30 min"
            ? "Use Stripe for secure payment"
            : "3 sales in last 30 min";
        visible = true;
      }, 300);
    }, 4000);

    return () => clearInterval(interval);
  });
</script>

{#if showModal}
  <div class="modal-overlay">
    <div class="modal">
      <button class="close-button" on:click={closeModal}>×</button>
      <h2>Unlock all sites now</h2>
      <!-- <p class="service-name">Service: {selectedService}</p> -->
      <div class="timer-container">
        <p class="subheader">
          Use code
          <strong class="strong">"LAUNCH"</strong> for 50€ off (2 left)
        </p>
      </div>
      <div class="price-container">
        <span class="original-price">79€</span>
        <span class="price">29€</span>
        <p class="lifetime-access">one time, life time access</p>
      </div>
      <div class="modal-buttons">
        <button
          class="access-button plausible-event-name=demoaction"
          on:click={handlePayPalClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M13 2L3 14h9l-1 8 10-12h-9l1-8z" />
          </svg>
          Get Full Access
        </button>
      </div>
      {#if visible}
        <p class="sales-message" transition:fade={{ duration: 300 }}>
          {salesMessage}
        </p>
      {/if}
    </div>
  </div>
{/if}

<style>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
  }

  .modal {
    background-color: #1a1a1a;
    padding: 2em;
    border-radius: 8px;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    position: relative;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #888;
    font-size: 24px;
    cursor: pointer;
    padding: 5px 10px;
    transition: color 0.2s;
  }

  .close-button:hover {
    color: #ff3e00;
  }

  .strong {
    color: #ff3e00;
  }

  .modal h2 {
    color: #1a73e8;
    margin-top: 0;
    margin-bottom: 0.5em;
    font-size: 2.5em;
  }

  .timer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0.5em 0;
  }

  .subheader {
    color: #999999;
    margin: 0;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  .price-container {
    text-align: center;
    margin: 2em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
  }

  .original-price {
    font-size: 1.5em;
    color: #666;
    text-decoration: line-through;
  }

  .price {
    font-size: 4em;
    font-weight: bold;
    color: #ff3e00;
    line-height: 1;
  }

  .lifetime-access {
    margin: 0;
    color: #ffffff7a;
    font-size: 1em;
  }

  .modal-buttons {
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }

  .access-button {
    background: linear-gradient(135deg, #1a73e8, #0d47a1);
    color: white;
    border: none;
    padding: 15px 40px;
    font-size: 1.2em;
    font-weight: bold;
    border-radius: 30px;
    cursor: pointer;
    transition:
      transform 0.2s,
      box-shadow 0.2s;
    box-shadow: 0 4px 15px rgba(26, 115, 232, 0.3);
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .access-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(26, 115, 232, 0.4);
  }

  .sales-message {
    text-align: center;
    color: #999;
    font-size: 0.9em;
    margin: 1em 0 0 0;
    min-height: 1.2em;
  }
</style>
