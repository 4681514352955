<script>
  import { onMount } from "svelte";
  let showBanner = true;

  onMount(() => {
    if (localStorage.getItem("cookieConsent")) {
      showBanner = false;
    }
  });

  const acceptCookies = () => {
    localStorage.setItem("cookieConsent", "true");
    showBanner = false;
  };

  const denyCookies = () => (showBanner = false);
</script>

{#if showBanner}
  <div
    class="fixed bottom-0 w-full z-50 bg-gray-900/90 backdrop-blur-sm border-t border-gray-800"
  >
    <div
      class="container mx-auto px-4 py-3 flex items-center justify-between gap-4 text-sm text-gray-200"
    >
      <p class="flex-1">
        We use cookies to improve your experience.
        <a
          href="/privacy"
          class="text-green-400 hover:text-green-300 ml-1 underline-offset-2 hover:underline"
        >
          Learn more
        </a>
      </p>
      <div class="flex gap-2 shrink-0">
        <button
          on:click={denyCookies}
          class="px-3 py-1.5 text-gray-300 hover:bg-gray-700 rounded transition-colors"
        >
          Decline
        </button>
        <button
          on:click={acceptCookies}
          class="px-3 py-1.5 bg-green-600 text-white rounded hover:bg-green-500 transition-colors"
        >
          Accept
        </button>
      </div>
    </div>
  </div>
{/if}
