<script>
  // Removed modal imports and state variables
</script>

<footer>
  <div class="footer-content">
    <div class="legal-links">
      <a href="/terms">Terms of Service</a>
      <a href="/privacy">Privacy Policy</a>
    </div>
    <div class="copyright">
      © {new Date().getFullYear()} Everymov.com - All rights reserved.
    </div>
  </div>
</footer>

<style>
  a {
    color: #888;
    font-size: 0.9rem;
    text-decoration: none;
    transition: color 0.2s;
  }

  a:hover {
    color: #ffd700;
  }

  footer {
    bottom: 0;
    width: 100%;
    background-color: #242424;
    padding: 1rem 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
    margin-top: auto;
  }

  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .legal-links {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  .copyright {
    color: #666;
    font-size: 0.8rem;
  }
</style>
