<script>
  export let testimonials = [
    {
      username: "User",
      date: "Jan 30, 2025",
      stars: 4,
      text: "Wow this makes looking for porn so much easier.",
    },
    {
      username: "User",
      date: "Jan 29, 2025",
      stars: 5,
      text: "Neve knew what I missed. Having access to so many sites does make a huge difference.",
    },
    {
      username: "User",
      date: "Jan 27, 2025",
      stars: 5,
      text: "This is fkn great! I have so many videos in my head! Worked brilliantly sofar 5⭐️",
    },
    {
      username: "User",
      date: "Jan 26, 2025",
      stars: 5,
      text: "This is exactly what I was looking for.",
    },
    {
      username: "User",
      date: "Jan 23, 2025",
      stars: 1,
      text: "There is really every video out there. FOR FREE!",
    },
    {
      username: "User",
      date: "Jan 23, 2025",
      stars: 5,
      text: "Fuck me. Now I wont get off porn for sure.",
    },
    {
      username: "User",
      date: "Jan 21, 2025",
      stars: 5,
      text: "This is awesome. ",
    },
    {
      username: "User",
      date: "Jan 18, 2025",
      stars: 5,
      text: "EveryMov turns every session into something exciting!",
    },
    {
      username: "User",
      date: "Jan 16, 2025",
      stars: 4,
      text: "Very nice. This saves me a lot of time.",
    },
    {
      username: "User",
      date: "Jan 15, 2025",
      stars: 3,
      text: "Would be nice to add sites...",
    },
    {
      username: "User",
      date: "Jan 14, 2025",
      stars: 5,
      text: "EveryMov makes looking for videos possible. Before it was just f*cked",
    },
    {
      username: "User",
      date: "Jan 12, 2025",
      stars: 5,
      text: "Don't know 99% of the sites, but who cares. I found all videos. Incredible. Would be nice to safe links here... ",
    },
  ];
  import i18n from "@/lib/i18n";
  $: t = $i18n.getFixedT(null, null, "testimonials");
</script>

<section class="testimonials">
  <h2 class="title">{t("title")}</h2>
  <div class="container">
    {#each testimonials as testimonial}
      <div class="testimonial-card">
        <div class="header">
          <div class="user-info">
            <div class="user-meta">
              <h3 class="username">{testimonial.username}</h3>
              <span class="date">• {testimonial.date}</span>
            </div>
            <div class="stars">
              {"★".repeat(testimonial.stars)}
            </div>
          </div>
        </div>
        <p class="testimonial-text">{testimonial.text}</p>
      </div>
    {/each}
  </div>
</section>

<style>
  .testimonials {
    background-color: #1a1a1a;
    color: white;
    padding: 40px 0;
  }
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 16px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
  }
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
    color: #d0d0d0;
    padding: 40px 16px 0;
  }
  .testimonial-card {
    background-color: #2a2a2a;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition:
      transform 0.3s ease,
      box-shadow 0.3s ease;
  }
  .testimonial-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
  }
  .header {
    margin-bottom: 12px;
  }
  .user-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .user-meta {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .username {
    font-size: 1.5rem;
    margin: 0;
    color: #f0f0f0;
  }
  .date {
    font-size: 0.9rem;
    color: #888;
  }
  .stars {
    color: #ffd700;
    font-size: 0.9rem;
  }
  .testimonial-text {
    font-size: 1.2rem;
    color: #ccc;
    line-height: 1.4;
    margin: 0;
    font-style: italic;
  }

  @media (max-width: 600px) {
    .testimonials {
      padding: 60px 0 40px 0;
    }
    .title {
      font-size: 3rem;
      line-height: 1.2;
    }
  }
</style>
