<script>
  import { Router, Route } from "svelte-routing";
  import Home from "./pages/Home.svelte";
  import LP from "./pages/LP.svelte";
  import Terms from "./pages/Terms.svelte";
  import Privacy from "./pages/Privacy.svelte";
  import Demo from "./pages/Demo.svelte";
  import Testimonials from "./pages/Components/Testimonials.svelte";
  import posthog from "posthog-js";
  import Home2 from "./pages/Home2.svelte";
  import LP2 from "./pages/LP2.svelte";

  posthog.init("phc_7406qnbLDrulbmhd20k94o40ogazfgBoPekA3dOW1mv", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only",
  });
</script>

<Router>
  <Route path="/" component={LP} />
  <Route path="/new" component={LP2} />
  <Route path="/app_41234" component={Home} />
  <Route path="/terms" component={Terms} />
  <Route path="/privacy" component={Privacy} />
  <Route path="/demo" component={Demo} />
  <Route path="/wall" component={Testimonials} />
  <Route path="/home" component={Home2} />
</Router>
