export const services = {
  // NEW SHIT

  // Direct URL services
  xvideos: { url: "https://xvideos.com/?k=", premium: false },
  pornhub: { url: "https://pornhub.com/video/search?search=", premium: false },
  xhamster: { url: "https://xhamster.com/search/", premium: false },
  xnxx: { url: "https://www.xnxx.com/search/", premium: false },
  eporner: { url: "https://www.eporner.com/search/", premium: false },
  youporn: {
    url: "https://www.youporn.com/search/?search-btn=&query=",
    premium: false,
  },
  redtube: { url: "https://redtube.com/?search=", premium: false },
  pornone: { url: "https://pornone.com/search?q=", premium: false },
  tubesafari: { url: "https://tubesafari.com/search/", premium: false },
  xtits: { url: "https://www.xtits.xxx/search/", premium: false },
  pornzog: { url: "https://pornzog.com/search/?s=", premium: false },
  porn300: {
    url: "https://www.porn300.com/search/?q=",
    premium: false,
  },
  hclips: { url: "https://hclips.com/search/1/?s=", premium: false },
  zbporn: { url: "https://zbporn.com/search/", premium: false },
  sexvid: { url: "https://sexvid.xxx/s/", premium: false },
  upornia: { url: "https://upornia.com/search/1/?s=", premium: false },
  pornkai: { url: "https://pornkai.com/videos?q=", premium: false },
  sunporno: { url: "https://www.sunporno.com/search/", premium: false },
  tube8: { url: "https://www.tube8.com/searches.html/?q=", premium: false },
  // "3movs": { url: "https://www.3movs.com/search_videos/?q=", premium: false },

  letmejerk: { url: "https://www.letmejerk.com/search.php?q=", premium: false },
  tubev: { url: "https://www.tubev.sex/search?search=", premium: false },
  alohatube: { url: "https://www.alohatube.com/", premium: false },
  pornmaki: { url: "https://pornmaki.com/search/videos/", premium: false },
  hello: { url: "https://hello.porn/search/", premium: false },
  smutr: { url: "https://smutr.com/videos/?k=", premium: false },
  cumlouder: { url: "https://www.cumlouder.com/search/?q=", premium: false },
  hdxnxx: { url: "https://hdxnxx.xxx/en/search/", premium: false },
  xcafe: { url: "https://xcafe.com/videos/", premium: false },
  txxx: { url: "https://txxx.com/search/?s=", premium: false },
  porntube: {
    url: "https://www.porntube.com/videos?term=",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "+"),
  },
  shameless: {
    url: "https://shameless.com/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  },
  xbabe: { url: "https://xbabe.com/search/?q=", premium: false },
  spankbang: {
    url: "https://spankbang.com/s/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  },
  pornid: {
    url: "https://www.pornid.xxx/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "+") + "/",
  },
  rat: {
    url: "https://www.rat.xxx/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "+") + "/",
  },
  hdtube: {
    url: "https://de.hdtube.porn/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "+") + "/",
  },
  porndroids: { url: "https://www.porndroids.com/search/?q=", premium: false },
  pussyspace: { url: "https://www.pussyspace.com/search?q=", premium: false },
  hdzog: { url: "https://hdzog.com/search/?s=", premium: false },
  hellporno: { url: "https://hellporno.com/search/?q=", premium: false },
  sex3: { url: "https://sex3.com/search/?q=", premium: false },
  porndig: { url: "https://www.porndig.com/videos/s=", premium: false },
  porndoe: { url: "https://porndoe.com/search?keywords=", premium: false },
  youjizz: {
    url: "https://www.youjizz.com/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "-") + "-1.html?",
  },
  motherless: { url: "https://motherless.com/term/", premium: false },
  jizzbunker: {
    url: "https://jizzbunker.com/de/search?query=",
    premium: false,
  },
  drtuber: { url: "https://www.drtuber.com/search/videos/", premium: false },
  nuvid: { url: "https://www.nuvid.com/search/videos/", premium: false },
  alphaporno: { url: "https://www.alphaporno.com/search/?q=", premium: false },
  pornoxo: { url: "https://www.pornoxo.com/search/?q=", premium: false },
  tnaflix: { url: "https://www.tnaflix.com/search?what=", premium: false },
  empflix: { url: "https://www.empflix.com/search?what=", premium: false },
  xxxbunker: { url: "https://xxxbunker.com/search/", premium: false },
  pornhost: { url: "https://pornhost.com/search.html?search=", premium: false },

  definebabe: {
    url: "https://www.definebabe.com/search/?f=1&q=",
    premium: false,
  },
  pornburst: { url: "https://www.pornburst.xxx/search/?q=", premium: false },
  submityourflicks: {
    url: "https://www.submityourflicks.com/search/",
    premium: false,
  },
  nonktube: { url: "https://www.nonktube.com/?s=", premium: false },
  boysfood: {
    url: "https://www.boysfood.com/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  },
  moviefap: { url: "https://www.moviefap.com/search/", premium: false },
  hdpornstar: {
    url: "https://hdpornstar.com/?s=",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "+"),
  },
  al4a: {
    url: "https://www.al4a.com/tag/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  },
  stileproject: { url: "https://www.stileproject.com/search/", premium: false },

  pornomovies: {
    url: "https://www.pornomovies.com/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  },
  pornheed: { url: "https://www.pornheed.com/s/", premium: false },
  dirtydirtyangels: {
    url: "https://www.dirtydirtyangels.com/search/video/?s=",
    premium: false,
  },

  maturetube: { url: "https://www.maturetube.com/search/", premium: false },
  yourpornjizz: { url: "https://www.yourpornjizz.com/?s=", premium: false },
  yporn: { url: "https://www.yporn.tv/?s=", premium: false },
  maxjizztube: { url: "https://www.maxjizztube.com/?s=", premium: false },

  anysex: { url: "https://anysex.com/search/?q=", premium: false },

  babestube: {
    url: "https://www.babestube.com/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  },
  youngleafs: {
    url: "https://youngleafs.com/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "-") + "-videos",
  },
  momvids: {
    url: "https://www.momvids.com/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  },
  bigtitslust: {
    url: "https://www.bigtitslust.com/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  },
  hoestube: {
    url: "https://hoes.tube/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  },
  abxxx: {
    url: "https://abxxx.com/search/1/?s=",
    premium: false,
  },
  xxxshake: {
    url: "https://xxxshake.com/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  },
  xhand: {
    url: "https://xhand.net/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  },
  hotporntubes: {
    url: "https://www.hotporntubes.com/",
    premium: false,
  },
  pornito: {
    url: "https://pornito.xxx/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "-") + "-nn74y8/",
  },
  handjobhub: {
    url: "https://handjobhub.com/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  },
  porn7: {
    url: "https://www.porn7.xxx/search?search=",
    premium: false,
  },

  //
  //
  //
  //
  //
  // Search Directories

  dinotube: { url: "https://www.dinotube.com/search/a/", premium: false },
  fuq: { url: "https://www.fuq.com/search/a/", premium: false },
  iwank: {
    url: "https://iwank.tv/en/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "+") + "/",
  },
  ixxx: { url: "https://www.ixxx.com/search/a/", premium: false },
  tonicmovies: {
    url: "https://tonicmovies.com/en/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "_") + "/",
  },
  tubegalore: { url: "https://www.tubegalore.com/search/a/", premium: false },

  gotporn: { url: "https://www.gotporn.com/search/a/", premium: false },

  tiava: { url: "https://www.tiava.com/search/a/", premium: false },

  "4tube": { url: "https://www.4tube.com/search?q=", premium: false },

  porn: { url: "https://www.porn.com/search?sq=", premium: false },

  pornerbros: { url: "https://www.pornerbros.com/search?q=", premium: false },

  fux: { url: "https://www.fux.com/search?q=", premium: false },

  xxxvideos247: {
    url: "https://www.xxxvideos247.com/straight/search/",
    premium: false,
    queryTransform: (query) => query.replace(/\s+/g, "%20") + ".html",
  },
  lesbianpornvideos: {
    url: "https://www.lesbianpornvideos.com/search/a/",
    premium: false,
  },
  //
  //
  //
  //
  //
  // Baiting Sites (sites that only promote paid sites)
  // ok: {
  //   url: "https://ok.xxx/search/",
  //   premium: false,
  //   queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  // },
  // pornhat: {
  //   url: "https://www.pornhat.com/search/",
  //   premium: false,
  //   queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  // },

  // xozilla: { url: "https://www.xozilla.xxx/search/", premium: false },

  // perfectgirls: {
  //   url: "https://www.perfectgirls.xxx/search/",
  //   premium: false,
  //   queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  // },

  // megatube: {
  //   url: "https://www.megatube.xxx/search/",
  //   premium: false,
  //   queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  // },

  // porntop: { url: "https://porntop.com/search/", premium: false },

  // alotporn: {
  //   url: "https://www.alotporn.com/search/",
  //   premium: false,
  //   queryTransform: (query) => query.replace(/\s+/g, "-") + "/",
  // },

  // cliphunter: { url: "https://cliphunter.com/search/", premium: false },

  // nextdoordolls: {
  //   url: "https://www.nextdoordolls.com/search",
  //   premium: false,
  // },

  // irgednwie kake
  // beeg: { url: "https://beeg.com/", premium: false }, gibt keine richtige Suche..
  // pornotube: {
  //   url: "https://www.pornotube.com/orientation/straight/search/text/term/",
  //   premium: false,
  // },
  // tubixe: {
  //   url: "https://www.tubixe.com/site/recherche/redirect/",
  //   premium: false,
  // },
  // desihoes: { url: "https://www.desihoes.com/search/videos/", premium: false },
};
