<script>
  import { Play, Icon } from "svelte-hero-icons";
  import i18n from "@/lib/i18n";

  function switchLanguage(event) {
    const newLang = event.target.value;
    $i18n.changeLanguage(newLang);
  }
</script>

<div class="header">
  <div class="left-section">
    <Icon
      src={Play}
      style="width: 24px; height: 24px; margin-right: 8px; fill: #ffd700;"
    />
    <a href="/" class="funny-font">EveryMov</a>
  </div>
  <div class="right-section">
    <!-- <a href="/#pricing-section">Pricing</a> -->
    <a href="/#questions-section">Q&A</a>
    <!-- <a href="/#testimonial-section">{$i18n.t("header.testimonials")}</a> -->
    <select
      value={$i18n.language}
      on:change={switchLanguage}
      class="language-select"
    >
      <option value="en">🇬🇧 EN</option>
      <option value="de">🇩🇪 DE</option>
      <option value="fr">🇫🇷 FR</option>
      <option value="es">🇪🇸 ES</option>
      <option value="pl">🇵🇱 PL</option>
      <option value="nl">🇳🇱 NL</option>
      <option value="pt">🇧🇷 PT</option>
    </select>
  </div>
</div>

<style>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px; /* Reduced padding on the x-axis */
    background-color: #000; /* Added background color */
  }
  .left-section {
    display: flex;
    align-items: center;
  }
  .right-section {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .right-section a {
    text-decoration: none;
    color: gray;
    font-size: 1rem;
    font-weight: normal;
  }
  .language-select {
    background-color: transparent;
    color: gray;
    border: 1px solid #333;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 0.9rem;
    cursor: pointer;
    outline: none;
  }
  .language-select:hover {
    border-color: #444;
  }
  .language-select option {
    background-color: #1a1a1a;
    color: gray;
  }
  .left-section a {
    color: white; /* White text color */
    font-size: 1.2rem; /* Bigger font size */
    font-weight: bold; /* Fatter font */
    text-decoration: none; /* Remove underline from link */
  }

  .funny-font {
    font-size: small;
  }
</style>
