<script>
  export let searchText = "";
  export let systemMessage = "";
</script>

<div class="search-container">
  <div class="search-wrapper">
    <svg
      class="search-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path
        fill="#999"
        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
      />
    </svg>
    <input
      type="text"
      bind:value={searchText}
      placeholder="Type here, then select a site."
      class="search-input"
    />
  </div>
  {#if systemMessage}
    <p class="system-message">{systemMessage}</p>
  {/if}
</div>

<style>
  .search-container {
    margin: 2em auto;
    width: 95%;
    max-width: 584px;
    padding-top: 1em;
    padding-bottom: 4em;
  }

  .search-wrapper {
    position: relative;
    width: 100%;
    z-index: 0;
  }

  .search-icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
  }

  .search-input {
    width: 100%;
    padding: 1em 1em 1em 50px;
    font-size: 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 24px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.28);
    background-color: #2a2a2a;
    color: #ffffff;
  }

  .search-input::placeholder {
    color: #999;
  }

  .search-input:focus {
    outline: none;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.35);
    border-color: rgba(255, 255, 255, 0.2);
  }

  .system-message {
    color: #ff4444;
    font-size: 0.9em;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    padding-top: 1em; /* Added top padding */
  }
</style>
