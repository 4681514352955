<script>
  import { onDestroy } from "svelte";
  import { getPaymentLink } from "../../lib/stripe";

  let timerElement;
  let minutes = 3;
  let seconds = 0;

  const interval = setInterval(() => {
    if (seconds === 0) {
      if (minutes === 0) {
        clearInterval(interval);
        return;
      }
      minutes--;
      seconds = 59;
    } else {
      seconds--;
    }
  }, 1000);

  onDestroy(() => {
    clearInterval(interval);
  });
</script>

<section class="min-h-screen flex flex-col justify-center py-16 text-white">
  <div class="max-w-6xl mx-auto px-4 w-full">
    <div class="text-center mb-4">
      <h2 class="text-4xl md:text-5xl font-bold mb-4">Pick a package:</h2>
      <!-- <p class="text-xl text-gray-400 max-w-2xl mx-auto">
        Get instant access to our platform with the package that suits you best
      </p> -->
    </div>
    <!-- Timer -->
    <div class="text-white/50 text-center pb-8">
      <div class="text-sm mb-1">⏳ Special offer ends in:</div>
      <div class="text-xl font-mono" bind:this={timerElement}>
        {minutes}:{seconds < 10 ? "0" + seconds : seconds}
      </div>
    </div>

    <div class="flex flex-col gap-2 max-w-2xl mx-auto">
      <a
        href={getPaymentLink()}
        class="bg-gray-800 p-4 flex justify-between items-center border border-gray-700"
      >
        <div>
          <h3 class="text-lg text-gray-300">1 YEAR</h3>
          <p class="text-sm text-gray-500">No subscription</p>

          <span class="text-sm text-gray-400">33% Discount</span>
        </div>
        <div class="text-right">
          <span class="block text-sm text-gray-500 line-through">$29,90</span>

          <span class="text-xl text-gray-300">$18,90</span>
        </div>
      </a>
      <a
        href={getPaymentLink(true)}
        class="bg-gray-800 p-4 flex justify-between items-center border border-gray-700"
      >
        <div>
          <h3 class="text-lg text-gray-300">LIFETIME</h3>
          <p class="text-sm text-gray-500">No subscription</p>

          <span class="text-sm text-gray-400">50% Discount</span>
        </div>
        <div class="text-right">
          <span class="block text-sm text-gray-500 line-through">$45,90</span>

          <span class="text-xl text-gray-300">$22,90</span>
        </div>
      </a>
    </div>
    <!-- Payment Provider Logos -->
    <div class="flex justify-center pb-8">
      <div
        class="inline-flex justify-center items-center gap-6 mt-4 px-8 py-3 rounded-lg"
      >
        <img
          src="pp.png"
          alt="PayPal"
          class="h-8 object-contain opacity-75 hover:opacity-100 transition-opacity"
        />
        <img
          src="mc.png"
          alt="Mastercard"
          class="h-8 object-contain opacity-75 hover:opacity-100 transition-opacity"
        />
        <img
          src="apple.png"
          alt="Apple Pay"
          class="h-8 object-contain opacity-75 hover:opacity-100 transition-opacity"
        />
      </div>
    </div>
  </div>
</section>
